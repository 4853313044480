// src/components/NavBar/NavBar.tsx

// Import all the necessary dependencies
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import './NavBar.css';
// Importing icons from react-icons
import { FaTimes } from 'react-icons/fa';
import { BiMenu } from 'react-icons/bi';
import { GrLanguage } from 'react-icons/gr';
// Importing images
// import logo from '../../assets/images/brand/logo.webP';
import homeIcon from '../../assets/images/general/icons/header-icon-home.svg';
import careersIcon from '../../assets/images/general/icons/header-icon-careers.svg';
// import linkedinIcon from '../../assets/images/general/icons/header-icon-linkedin.svg';
import twitterIcon from '../../assets/images/general/icons/header-icon-twitter.svg';
import instagramIcon from '../../assets/images/general/icons/header-icon-instagram.svg';
// import facebookIcon from '../../assets/images/general/icons/header-icon-facebook4.webP';
// Importing translation hook from react-i18next
import { useTranslation } from 'react-i18next';
// Importing swipeable hook from react-swipeable
import { useSwipeable } from 'react-swipeable';

// Component definition
function NavBar() {
  // Initialize states
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const [isHovered, setHovered] = useState<Record<number, boolean>>({});
  const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);

  // Translation hook
  const { t, i18n } = useTranslation();
  // Checking if the current language is Hebrew (RTL)
  const isRTL = i18n.language === 'he';

  // Reference for the side menu
  const sideMenuRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {

    // Function to handle click outside of the side menu
    const handleClickOutside = (event: MouseEvent) => {
      // If clicked outside of the side menu, close the menu
      if (sideMenuRef.current && event.target instanceof Node && !sideMenuRef.current.contains(event.target)) {
        setSideMenuOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sideMenuRef]);

  // Function to handle language menu toggle
  const handleLanguageMenuToggle = () => {
    setLanguageMenuOpen(!isLanguageMenuOpen);
  };

  // Function to handle language change
  const handleLanguageChange = (language: any) => {
    i18n.changeLanguage(language);
    setLanguageMenuOpen(false);
  };

  // Function to handle mouse enter event on menu item
  const handleMouseEnter = (index: number) => {
    setHovered((prevState) => ({ ...prevState, [index]: true }));
  };

  // Function to handle mouse leave event on menu item
  const handleMouseLeave = (index: number) => {
    setHovered((prevState) => ({ ...prevState, [index]: false }));
  };

  // Define navigation items
  const navItems = [
    // { name: t('navBar.home'), link: '/home' },
    { name: t('links.aboutUs'), link: '/about' },
    { name: t('links.ourServices'), link: '/services' },
    { name: t('links.projects'), link: '/projects' },
    { name: t('links.subsidiaries'), link: '/subsidiaries' },
    { name: t('links.faq'), link: '/faq' },
    { name: t('links.contact'), link: '/contact' },
  ];

  // Define navigation items
  const navItemsMobile = [
    // { name: t('navBar.home'), link: '/home' },
    { name: t('links.aboutUs'), link: '/about' },
    { name: t('links.ourServices'), link: '/services' },
    { name: t('links.projects'), link: '/projects' },
    { name: t('links.subsidiaries'), link: '/subsidiaries' },
    { name: t('links.faq'), link: '/faq' },
    { name: t('links.careers'), link: '/careers' },
    { name: t('links.contact'), link: '/contact' },
  ];

  const handlers = useSwipeable({
    onSwipedLeft: () => setSideMenuOpen(true),
    trackMouse: true,
  });

  return (
    <nav className="navBar">
      {/* <div className="navBar-icons-left" style={{ display: 'flex', justifyContent: 'space-between' }}>*/}
      <div className="navBar-icons-left">
        <div className={`language-dropdown ${isLanguageMenuOpen ? 'open' : ''}`}>
          <div className="flex items-center">
            <button className={"language-icon"} onClick={handleLanguageMenuToggle}>
              <GrLanguage
                title={t('navBar.language.menu') as string}
              />
            </button>
            <div className="pl-1">
              {t('navBar.language.icon')}
            </div>
          </div>
          <ul className={`language-menu language-menu-items`}>
            <li>
              <button onClick={() => handleLanguageChange('en')}>
                {t('navBar.language.en')}
              </button>
            </li>
            <li>
              <button onClick={() => handleLanguageChange('he')}>
                {t('navBar.language.he')}
              </button>
            </li>
          </ul>
        </div>
        <div className="navBar-icons-left-link">
          <Link to="/">
            {/* <BiHomeAlt /> */}
            <img
              src={homeIcon}
              alt="Home"
              title={t('links.home') as string}
              style={{ width: '36px', height: '36px' }}
            />
            <span className="icon-label">{t('navBar.home')}</span>
          </Link>
          <Link to="/careers">
            {/* <BiBriefcase /> */}
            <img
              src={careersIcon}
              alt="Careers"
              title={t('links.careers') as string}
              // width="100%"
              // height="100%"
              style={{ width: '36px', height: '36px' }}
            // className='navBar-icons-left'
            />
            <span className="icon-label">{t('links.careers')}</span>
          </Link>
        </div>
      </div>

      <div className="navBar-center">
        <Link className="logo-container" to="/">
          <img
            src="../../assets/images/brand/logo.webP"
            alt="Logo"
            title="Logo"
            width="500"   // Set width explicitly
            height="149" // Set height explicitly
            style={{
              display: 'block',
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Link>

        {/* Mobile menu button, visible only on mobile */}
        <div className="navBar-mobile" style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
          <button
            onClick={() => setSideMenuOpen(!isSideMenuOpen)}
            style={{ transition: "transform 0.5s" }}
            aria-label={isSideMenuOpen ? "Close menu" : "Open menu"}
          >
            {isSideMenuOpen ? <FaTimes color='red' style={{ transform: "scale(1.1)" }} /> : <BiMenu style={{ transform: "scale(1.1)" }} />}
          </button>
          {/* Side menu for mobile, hidden on desktop */}
          <div {...handlers} ref={sideMenuRef} className={`side-menu ${isSideMenuOpen ? 'open' : ''}`}>
            {navItemsMobile.map((item, index) => (
              <Link
                key={index}
                to={item.link}
                className="navBar-item"
                onClick={() => setSideMenuOpen(false)}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
        <div className="navBar-links" style={{ direction: isRTL ? 'rtl' : 'ltr', textAlign: "center" }}>
          {navItems.map((item, index) => (
            <div
              key={index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
              className={`navBar-item ${isHovered[index] ? 'hovered' : ''}`}
            >
              <Link to={item.link}>{item.name}</Link>
            </div>
          ))}
        </div>
      </div>
      <div className="navBar-icons-right">
        {/* <Link to={t('socialMedia.linkedIn') as string}>
          <img src={linkedinIcon} alt="LinkedIn" title={t('navBar.linkedin') as string} />
          <span className="icon-label">{t('navBar.linkedin')}</span>
        </Link> */}
        <Link to={t('socialMedia.twitter') as string} aria-label={t('navBar.twitter') as string}>
          {/* <FaTwitter /> */}
          <img
            src={twitterIcon}
            alt="Twitter"
            title={t('navBar.twitter') as string}
            // width="100%"
            // height="100%"
            style={{ width: '36px', height: '36px' }}
          // className='navBar-icons-right' // inhered from parent

          />
          <span className="icon-label">{t('navBar.twitter')}</span>
        </Link>
        <Link to={t('socialMedia.instagram') as string} aria-label={t('navBar.instagram') as string}>
          {/* <FaInstagram /> */}
          <img
            src={instagramIcon}
            alt="Instagram"
            title={t('navBar.instagram') as string}
            // width="100%"
            // height="100%"
            style={{ width: '36px', height: '36px' }}
          // className='navBar-icons-right'
          />
          <span className="icon-label">{t('navBar.instagram')}</span>
        </Link>
        <Link to={t('socialMedia.facebook') as string} aria-label={t('navBar.facebook') as string}>
          {/* <FaInstagram /> */}
          <img
            src="../../assets/images/general/icons/header-icon-facebook4.webP"
            alt="Facebook"
            title={t('navBar.facebook') as string}
            style={{ width: '36px', height: '36px' }}
          // className='navBar-icons-right'
          />
          <span className="icon-label">{t('navBar.facebook')}</span>
        </Link>
      </div>
    </nav>
  );
}
export default NavBar;