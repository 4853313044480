// src/index.tsx

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from './translations/en.json';
import common_he from './translations/he.json';
import { createRoot } from 'react-dom/client';
import { Cookies } from 'react-cookie-consent';

// get the system language from the browser
const systemLanguage = navigator.language.slice(0,2)
const savedLanguage = Cookies.get('userLanguage') || systemLanguage || 'he';  // Fallback to 'he' if no cookie is set

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: common_en,
    },
    he: {
      translation: common_he,
    },
  },
  lng: savedLanguage,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  returnObjects: true, // add this line
});


const root = document.getElementById('root') || document.createElement('div');

createRoot(root).render(<App />);

reportWebVitals();