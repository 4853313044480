// SocialIcon.tsx

import React from 'react';

interface SocialIconProps {
    icon: JSX.Element;
    href: string;
}

const SocialIcon: React.FC<SocialIconProps> = ({ icon, href }) => {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-600 hover:text-slate-300 transition-colors duration-200"
        >
            {icon}
        </a>
    );
};

export default SocialIcon;