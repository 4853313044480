// src/components/ChatBot/ChatOption.tsx

import React from 'react';

type ChatOptionProps = {
  option: {
    title: string;
    description: string;
    value: string;
    answer: string;
  };
  onSelect: (option: { title: string; description: string; value: string; answer: string }) => void;
};

const ChatOption: React.FC<ChatOptionProps> = ({ option, onSelect }) => {
  return (
    <button
      className="block w-full text-left p-2 rounded-lg bg-indigo-200 text-black mb-2"
      onClick={() => onSelect(option)}
    >
      {option.title}
    </button>
  );
};

export default ChatOption;
