// src/components/ChatBot/ChatBot.tsx

import { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { IoChatbubbleEllipsesOutline, IoLogOutOutline, IoSend } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import Draggable from 'react-draggable';

import ChatMessage from './ChatMessage';
import ChatOption from './ChatOption';

type ChatMessageType = { from: 'user' | 'bot'; text: string };
type PreOptionType = { title: string; description: string; value: string; answer: string };


// The ChatBot component is a virtual assistant that helps users find information.
// It features a simple text-based interface where users can input their queries, and the bot responds with relevant information.
// It's designed to be mobile-friendly and intuitive to use, providing users with quick access to information without leaving the page they're on.
const ChatBot: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [messages, setMessages] = useState<ChatMessageType[]>([]);
    const [preOptions, setPreOptions] = useState<PreOptionType[]>([]);

    const isRTL = i18n.language === 'he';

    // Handle user's message submission
    const handleSendMessage = (event: React.FormEvent) => {
        event.preventDefault();
        const message = (event.target as any).message.value.trim();
        if (message !== '') {
            setMessages((prevMessages) => [...prevMessages, { from: 'user', text: message }]);
            (event.target as any).reset();
        }
    };

    useEffect(() => {
        // Display the bot's initial message
        if (isOpen && messages.length === 0) {
            setMessages([
                { from: 'bot', text: t('chatBot.greetingMessage') },
                { from: 'bot', text: t('chatBot.preOptionsMessage') }
            ]);
            // Setting some preOptions when chat bot opens
            setPreOptions(t('chatBot.preOptions', { returnObjects: true })); // Fetch preOptions from translations
        }
    }, [isOpen, messages.length, t]);

    // Handle user's preOption selection
    const handleSelectOption = (option: { title: string, description: string, value: string, answer: string }) => {
        setMessages((prevMessages) => [...prevMessages, { from: 'bot', text: option.description }, { from: 'bot', text: option.answer }, { from: 'bot', text: t('chatBot.exitMessage') }]);
        setPreOptions([]);
    };

    // Handle ending chat session
    const handleEndChat = () => {
        setMessages([]); // Clear the messages
        setPreOptions([]); // Clear the preOptions
        setIsOpen(false); // Close the chat window
    };

    return (
        <div className="fixed right-0 bottom-0 m-6 z-50 md:block hidden"
            style={{
                direction: isRTL ? 'rtl' : 'ltr',
            }}
        >
            <Draggable
                onDrag={() => { setIsDragging(true); }}
            >
                <div className="flex flex-col">

                    <Transition
                        show={isOpen}
                        enter="transition-opacity duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="mt-3 w-full md:w-64 p-4 bg-white rounded-lg shadow-lg "
                    >
                        <div className="overflow-y-auto h-64">
                            {messages.map((message, index) => (
                                <ChatMessage key={index} from={message.from} text={message.text} />
                            ))}
                            {preOptions.map((option, index) => (
                                <ChatOption key={index} option={option} onSelect={handleSelectOption} />
                            ))}
                        </div>
                        <div className="p-3 border-t border-gray-200">
                            <form onSubmit={handleSendMessage}>
                                <div className="flex space-x-2">
                                    <input
                                        name="message"
                                        type="text"
                                        placeholder={t('chatBot.button') || ''}
                                        className="flex-grow p-2 border rounded-lg text-sm focus:outline-none focus:border-indigo-500"
                                    />
                                    <button
                                        type="submit"
                                        className="p-2 bg-indigo-500 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <IoSend size="1.5em" /> {/* Using the icon here */}
                                    </button>
                                </div>
                            </form>
                            <button
                                title={t('chatBot.exit') as string}
                                onClick={handleEndChat}
                                className="mt-2 p-2 bg-red-500 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                <IoLogOutOutline size="1.5em" /> {/* Using the icon here */}
                            </button>
                        </div>
                    </Transition>
                    <button
                        title={t('chatBot.button') as string}
                        onClick={(e) => {
                            if (isDragging) {
                                setIsDragging(false);
                            } else {
                                setIsOpen(!isOpen);
                            }
                        }}
                        className="p-4 bg-indigo-500 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <IoChatbubbleEllipsesOutline size="1.5em" /> {/* Using the icon here */}
                    </button>
                </div>
            </Draggable>
        </div>
    );


};

export default ChatBot;


