// src/components/Footer/Footer.tsx

import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import FooterLink from './FooterLink';
import SocialIcon from './SocialIcon';
import { useTranslation } from 'react-i18next';
// import './Footer.css'

const Footer: React.FC = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.language === 'he';

    const footerLinks = [
        { name: t('links.home'), link: '/' },
        { name: t('links.aboutUs'), link: '/about' },
        { name: t('links.ourServices'), link: '/services' },
        { name: t('links.projects'), link: '/projects' },
        { name: t('links.subsidiaries'), link: '/subsidiaries' },
        { name: t('links.faq'), link: '/faq' },
        { name: t('links.contact'), link: '/contact' },
        { name: t('links.careers'), link: '/careers' },
    ];

    const chunkArray = (arr: any, chunkSize: any) => {
        const result = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            result.push(arr.slice(i, i + chunkSize));
        }
        return result;
    };

    const footerLinksColumns = chunkArray(footerLinks, 4);

    return (
        <footer className="bg-gray-800 text-white pt-2">
            <div className="mx-auto px-4 sm:px-6 lg:px-8" style={{
                // textAlign: "center",
                direction: isRTL ? 'rtl' : 'ltr',
            }}>
                {/* <div className="grid grid-cols-1 md:grid-cols-4 gap-4" > */}
                <div className="grid grid-cols-1 gap-4 md:grid-cols-[0.8fr,1fr,1fr,1fr]" >

                    {/* <div className="flex flex-wrap justify-between md:justify-start"> */}
                    {/* <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-4 justify-items-start md:justify-items-end" > */}
                    {/* "ml-[-200px]" */}
                    <div className="">
                        <h3 className="text-xl font-bold mb-1 pb-2">{t('companyDetails.companyName')}</h3>
                        <p className="text-gray-400 text-current">
                            {t('companyDetails.address')}
                        </p>
                        <p className="text-gray-400 text-current">{t('companyDetails.email')}</p>
                        <p className="text-gray-400 text-current">{t('companyDetails.phone')}</p>
                    </div>
                    <div className="">
                        <h3 className="text-xl font-bold mb-1 pb-2">{t('footer.quickLinks')}</h3>
                        <ul>
                            {footerLinksColumns[0].map((obj: any) => (
                                <FooterLink
                                    key={obj.name} // or key={link.to} if it is unique
                                    name={obj.name}
                                    to={obj.link}
                                />
                            ))}
                        </ul>
                    </div>
                    {footerLinksColumns.slice(1).map((column, colIdx) => (
                        <div key={colIdx} className="pt-2">
                            <ul className="mt-6 ">
                                {column.map((obj: any, index: any) => (
                                    <FooterLink
                                        key={index}
                                        name={obj.name}
                                        to={obj.link}
                                    />
                                ))}
                            </ul>
                        </div>
                    ))}
                    {/* <div className="continer"></div> */}
                    <div className="flex flex-col md:items-end">
                        <div className="flex flex-col">
                            <h3 className="text-xl font-bold mb-1 pb-2 items-start">{t('footer.followUs')}</h3>
                            <div className="flex flex-auto items-start">
                                <SocialIcon icon={<FaFacebook className={isRTL ? `mr-0` : `mr-4`} />} href={t('socialMedia.facebook')} />
                                <SocialIcon icon={<FaInstagram className="mr-4" />} href={t('socialMedia.instagram')} />
                                {/* <SocialIcon icon={<FaTwitter className="mr-4" />} href={t('socialMedia.twitter')} /> */}
                                <SocialIcon icon={<FaTwitter className={isRTL ? `mr-4` : `mr-0`} />} href={t('socialMedia.twitter')} />
                                {/* <SocialIcon icon={<FaLinkedinIn className={isRTL ? `mr-4` : `mr-0`} />} href={t('socialMedia.linkedinIn')} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4 border-t border-gray-700 pt-3 text-center text-gray-400 text-xs">
                {/* <p>&copy; {new Date().getFullYear()} {t('companyDetails.companyName')}. {t('footer.allRightsReserved')}</p> */}
                {/* <p dangerouslySetInnerHTML={{ __html: t('footer.copyright', { year: new Date().getFullYear(), companyName: t('companyDetails.companyName') }) }}></p> */}
                <p>{t('footer.copyright', { year: new Date().getFullYear(), companyName: t('companyDetails.companyName') })}</p>

            </div>
        </footer>

    );
};

export default Footer;