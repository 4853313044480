// src/components/CookieConsent/CookieConsent.tsx

import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { BiCookie } from 'react-icons/bi';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/**
 * The CookieConsent component displays a cookie consent banner at the bottom of the page.
 * The banner is displayed until the user accepts or declines the cookies.
 * Once the user accepts, a cookie is set in the browser to remember the user's choice,
 * and the banner is no longer displayed on subsequent visits.
 * If the user declines, the consent cookie is removed.
 * The banner is responsive and mobile-friendly.
 */
const CookieConsentBanner: React.FC = () => {
    const { t, i18n } = useTranslation();
    
    const handleAccept = () => {
        Cookies.set("userLanguage", i18n.language, { expires: 150 }); // Set language cookie when user accepts
    };

    const handleDecline = () => {
        Cookies.remove("refaeNaamanWebsiteHasUserConsentForCookies"); // Remove consent cookie when user declines
        Cookies.remove("userLanguage"); // Remove language cookie when user declines
    };
    
    return (
        <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText={t('button.accept')}
            onAccept={handleAccept}
            declineButtonText={t('button.decline')}
            onDecline={handleDecline}
            cookieSecurity
            cookieName="refaeNaamanWebsiteHasUserConsentForCookies"
            expires={150} // the consent cookie will expire after 150 days
            // overlay
            overlayStyle={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
            buttonStyle={{ backgroundColor: "#4caf50", color: "#fff", fontSize: "13px" }}
            declineButtonStyle={{ backgroundColor: "#f44336", color: "#fff", fontSize: "13px" }}
            style={{ background: "#2B373B", fontSize: "13px" }}
    

        >
            <div className="flex items-center justify-center">
                <BiCookie className="text-2xl text-white mr-2" /> {/* Cookie icon */}
                <span className="text-white">{t('cookie.description')}</span>
            </div>
            <div className="flex items-center justify-center mt-2">
                <AiOutlineCheckCircle className="text-2xl text-green-500 mr-2" /> {/* Check circle icon */}
                <span className="text-white">{t('cookie.messageAccept')}</span>
            </div>
            <div className="flex items-center justify-center mt-2">
                <Link to="https://www.cookiesandyou.com/" className="text-white underline">{t('cookie.linkInfo')}</Link> {/* Link to a website with more information about cookies */}
            </div>
        </CookieConsent>
    );
};

export default CookieConsentBanner;