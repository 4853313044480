// src/components/ChatBot/ChatMessage.tsx

import React from 'react';

type ChatMessageProps = {
  from: 'user' | 'bot';
  text: string;
};

const ChatMessage: React.FC<ChatMessageProps> = ({ from, text }) => {
  return (
    <div className={`mb-2 p-2 rounded-lg ${from === 'user' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-black'}`}>
      {text}
    </div>
  );
};

export default ChatMessage;
