// FooterLink.tsx

import React from 'react';
import { Link } from 'react-router-dom';

interface FooterLinkProps {
    name: string;
    to: string;
}

const FooterLink: React.FC<FooterLinkProps> = ({ name, to }) => {
    return (
        <>
            {/* {console.log("FooterLink.tsx: name: ", name, " to: ", to)} */}
            <li className="mb-1">
                <Link to={to} className="text-gray-600 hover:text-slate-300">
                    {name}
                </Link>
            </li>
        </>
    );
};

export default FooterLink;